import { Button, CheckboxGroup } from '@chakra-ui/react'
import { InputField, Label, ModalDialog, SwitchField } from '@kevea/react-components'
import { Form, Formik, FormikProps } from 'formik'
import { User } from 'models/user'
import { useRef, useState } from 'react'
import { UserService } from 'services/user'

type Props = {
	isOpen: boolean
	user: User
	onClose: () => void
}

export const UserModal = (props: Props) => {
	const formik = useRef<FormikProps<User>>(null)
	const [loading, setLoading] = useState(false)
	const [allowed, setAllowed] = useState(props.user.pinCode.allowed)
	const [changePincode, setChangePincode] = useState(!props.user.pinCode?.allowed)

	const handleSubmit = (values: User) => {
		if (formik.current) {
			setLoading(true)
			const newUser = new User(values)
			newUser.pinCode.pinCode = newUser.pinCode.allowed
				? newUser.pinCode.pinCode
				: ''
			UserService.updateMobileAccess(values)
				.then(res => res.data)
				.then(user => {
					setLoading(false)
					props.onClose()
				})
		}
	}

	return (
		<>
			{props.isOpen && (
				<ModalDialog
					isLoading={loading}
					header="Edytuj dostęp mobilny"
					onClose={() => props.onClose()}
					onSave={() => formik.current?.submitForm()}
					isOpen={props.isOpen}
				>
					<Formik
						validateOnBlur={false}
						validateOnChange={false}
						validationSchema={User.validationSchema}
						innerRef={formik}
						onSubmit={handleSubmit}
						initialValues={new User(props.user)}
					>
						<Form>
							<InputField
								name="firstName"
								placeholder="Imię"
								label="Imię"
								disabled
							/>
							<InputField
								name="lastName"
								placeholder="Nazwisko"
								label="Nazwisko"
								disabled
							/>
							<InputField
								name="email"
								placeholder="Adres Email"
								label="Adres Email"
								disabled
							/>
							<SwitchField
								name="pinCode.allowed"
								label="Dostęp mobilny"
								onChange={value => {
									setAllowed(value)
									if (!value) {
										formik.current?.setFieldValue('pinCode.pinCode', '')
									}
								}}
							/>
							{
								changePincode ?
									<InputField
										disabled={!allowed}
										name="pinCode.pinCode"
										placeholder="Kod PIN"
										label="Kod PIN"
										maxLength={6}
									/> :
									<Button variant='solid' style={{ marginTop: '12px' }} size='sm' onClick={() => {
										formik.current?.setFieldValue('pinCode.changePinCode', true)
										setChangePincode(true)
									}}>Zmień kod PIN</Button>
							}
							<SwitchField name='pinCode.permissions.attachements' label='Dostęp do załączników' isDisabled={!allowed} />
						</Form>
					</Formik>
				</ModalDialog>
			)}
		</>
	)
}
